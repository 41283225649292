import React, { useState, useEffect } from 'react'
import HeaderMob from './HeaderMob'
import HeaderEn from './HeaderEN'
import { isTablet } from 'react-device-detect';

const HeaderChoose = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenWidth]);

  return (
    (screenWidth < 769 || isTablet) ? <HeaderMob /> : <HeaderEn />
  )
}

export default HeaderChoose