import React from 'react'
import './Quotebtn.css';
import { Link } from 'react-router-dom';

const Quotebtn = () => {
  return (
    <Link to="/quote">
      <button className='quote-btn'>Get a Quote</button>
      </Link>
  )
}

export default Quotebtn