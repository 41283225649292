import React from 'react';
import './App.css';
import Contact from './pages/Contact/Contact';
import { BrowserRouter as Router, Routes, Route, withRouter } from 'react-router-dom';
import HomeEN from './pages/HomeEN/HomeEN';
import { AnimatePresence } from 'framer-motion';
import Quote from './pages/Quote/Quote';
import ScrollToTop from '../src/components/ScrollToTop/ScrollToTop';
import HeaderChoose from './components/Header/HeaderChoose';

const LazyFooter = React.lazy(() => import('./components/Footer/Footer'));

function App() {
  
  return (
    <div className="App">
      <Router>
        <div>
          <HeaderChoose />
          <ScrollToTop />
          <AnimatePresence mode="sync">
            <Routes key={window.location.pathname}>
              <Route exact path="/" element={<HomeEN/>} />
              <Route exact path="/contact" element={<Contact/>} />
              <Route exact path="/quote" element={<Quote/>} />
            </Routes>
          </AnimatePresence>
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyFooter />
          </React.Suspense>
        </div>
      </Router>
    </div>
  );
}

export default App;
