import React, { useEffect, useRef, useState } from 'react';
import './HomeEN.css';
import Quotebtn from '../../components/Quotebtn/Quotebtn';

const HomeEN = () => {
  const [isImageLoaded, setImageLoaded] = useState(false);
  const isImageLoadedRef = useRef(false);

  const reasonsRef = useRef([]);

  useEffect(() => {
    if (!isImageLoadedRef.current) {
      const img = new Image();
      img.src = 'laptop.png';
      img.onload = () => {
        console.log('Image loaded');
        setImageLoaded(true);
        isImageLoadedRef.current = true;
      };
      img.onerror = (error) => {
        console.log('Image failed to load:', error);
      };
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.4, // Adjust this threshold as needed
    };

    const handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('reason-visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);

    reasonsRef.current.forEach((reason) => {
      if (reason && reason instanceof Element) {
        observer.observe(reason);
      } else {
        console.error('Invalid element or element missing:', reason);
      }
    });
  //  const { isImageLoaded } = this.state;

    return () => observer.disconnect();
  });

  return (
    <div>
     {/*} <PreloadImage /> */}
      {isImageLoaded ? (
      <React.Suspense fallback={<div className="black-div"><div className="black-div-photo"></div></div>}>
      <div>
        <div className="photo-container"></div>
        <div className="frontpage-container">
          <div className='intro-container'>
            <h1>Unlock Your Business's Online Potential with Stunning Web Solutions</h1>
            <h2>Welcome to our Freelance Web Services</h2>
            <p className='intro-p'>
              Are you ready to take your business to the next level and leave a lasting impression on your customers?
              Look no further! WebSofta is here to transform your online presence with captivating web solutions
              that elevate your brand and boost your success.
            </p>
          </div>
          <div className="services-container">
            <div className="reasons-container">
              <div className="reason reason-hidden" ref={(el) => reasonsRef.current.push(el)}>
                <h3><span className='span-reason'>Tailored</span> Designs</h3>
                <p>We understand that every business is unique. Our expert team will work closely with you to create bespoke webpages, homepages, and landing pages that perfectly reflect your brand's personality and objectives.</p>
              </div>
              <div className="reason reason-hidden" ref={(el) => reasonsRef.current.push(el)}>
                <h3><span className='span-reason'>User-Centric</span> Approach</h3>
                <p>Your customers are at the heart of everything we do. Our designs are not only visually stunning but also intuitive and user-friendly, ensuring a seamless experience for every visitor.</p>
              </div>
              <div className="reason reason-hidden" ref={(el) => reasonsRef.current.push(el)}>
                <h3>Mobile-<span className='span-reason'>Responsive</span></h3>
                <p>In today's mobile-centric world, having a website that adapts to different devices is crucial. Our designs are fully responsive, guaranteeing a flawless appearance on smartphones, tablets, and desktops alike.</p>
              </div>
              <div className="reason reason-hidden" ref={(el) => reasonsRef.current.push(el)}>
                <h3>SEO-<span className='span-reason'>Optimized</span></h3>
                <p>Gain a competitive edge in search engine rankings with our search engine optimization (SEO) expertise. We structure your webpages to improve visibility and attract more organic traffic to your site.</p>
              </div>
              <div className="reason reason-hidden" ref={(el) => reasonsRef.current.push(el)}>
                <h3><span className='span-reason'>Fast</span> and <span className='span-reason'>Reliable</span></h3>
                <p>We know the value of time. Our efficient development process ensures timely delivery without compromising on quality, giving you a reliable online platform without delay.</p>
              </div>
              <div className="reason reason-hidden" ref={(el) => reasonsRef.current.push(el)}>
                <h3><span className='span-reason'>Affordable</span> Solutions</h3>
                <p>Big or small, we offer competitive pricing packages to accommodate businesses of all sizes. Our focus is to provide maximum value for your investment.</p>
              </div>
            </div>
          </div>
          <React.Suspense fallback={<div>Loading...</div>}>
            <div className='collab-container'>
              <h2 className='collab-h'>Let's Collaborate</h2>
              <p>
                Whether you're a startup looking to establish a strong online presence or an established business in need of a website overhaul,
                WebSofta's Freelance Web Services is here to turn your vision into reality.
              </p>
              <h3>Our Services Include:</h3>
              <ul>
                <li>Custom Website Design and Development</li>
                <li>Eye-catching Homepages</li>
                <li>Engaging Landing Pages</li>
                <li>Website Maintenance and Updates</li>
                <li>E-commerce Integration</li>
                <li>and much more!</li>
              </ul>
              <p><span className='contact-span'>Contact us</span> today to discuss your project and discover how our expertise can revolutionize your online identity. Let's embark on this exciting journey together and propel your business towards unmatched success!</p>
              <Quotebtn />
            </div>
          </React.Suspense>
        </div>
      </div>
          </React.Suspense>) : 
      <div className="black-div"><div className="black-div-photo"></div></div>}
    </div>
  );
};

export default HomeEN;
