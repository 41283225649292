import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Quote.css';
import AnimatedPage from '../../components/AnimatedPage/AnimatedPage';

const Quote = () => {
  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    topic: '',
    projectDescription: '',
    requirements: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the form data via EmailJS
    emailjs.sendForm('service_4omgsj9', 'template_h70xwwj', document.getElementById('quote-form-id'), 'I9-pawz5CfuRG5uCv')
      .then((result) => {
        console.log('Form submitted successfully', result.text);
        // Clear form fields
        setFormData(() => ({
          name: '',
          email: '',
          company: '',
          topic: '',
          projectDescription: '',
          requirements: ''
        }));
        setSent(true);
      })
      .catch((error) => {
        console.error('Error submitting form', error);
      });
  };

  return (
    <div className='quote-container'>
      <h1>Get a Quote</h1>
      <p className='quote-p'>Fill your project info and let's get started!</p>
      <form className='quote-form' id='quote-form-id' onSubmit={handleSubmit}>
        <div>
          <input 
          type="text" 
          name="name" 
          placeholder='Name' 
          value={formData.name}
          onChange={handleChange}
          onFocus={() => setSent(false)} 
          required />
        </div>
        <div>
          <input 
          type="text" 
          name="email"
          placeholder='Email'
          value={formData.email}
          onChange={handleChange}
          onFocus={() => setSent(false)}
          required />
        </div>
        <div>
          <input 
          type="text" 
          name="company" 
          placeholder='Company' 
          value={formData.company}
          onChange={handleChange}
          onFocus={() => setSent(false)}/>
        </div>
        <div className='topic-div'>
          <input 
          type="text" 
          name="topic" 
          placeholder='Topic' 
          value={formData.topic}
          onChange={handleChange}
          onFocus={() => setSent(false)}
          required />
        </div>
        <div>
          <textarea 
          name="projectDescription" 
          placeholder='Descripe your project' 
          value={formData.projectDescription}
          onChange={handleChange}
          required />
        </div>
        <div>
          <textarea 
          name="requirements" 
          placeholder='What do you need done?' 
          value={formData.requirements}
          onChange={handleChange}
          required />
        </div>
        <button className='send-btn' type="submit">Send info</button>
      </form>
      {(sent) ? <p className='p-sent'>Info sent</p> : <p className='empty-p'></p>}
    </div>
  );
};

export default Quote;
