import React, { useState} from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './HeaderMob.css';
import { HiMenuAlt2, HiOutlineX } from "react-icons/hi";

const HeaderMob = () => {
  const [showMenu, setShowMenu] = useState(false);

  const Menu = () => {
    return (
      <div className={`menu ${showMenu ? 'open' : ''}`}>
        <ul className='menu-list'>
          <li className='menu-item-0'>
            <Link to="/" onClick={() => setShowMenu(!showMenu)}>
              Home
            </Link>
          </li>
          <li className='menu-item-1'>
            <Link to="/quote" onClick={() => setShowMenu(!showMenu)}>
              Get a Quote
            </Link>
          </li>
          <li className='menu-item-2'>
            <Link to="/contact" onClick={() => setShowMenu(!showMenu)}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    )
  }

  
  return (
    <div className="mob-header">
      <div className="mob-header-container">
        <div className='logo-link-div'>
          <Link className='header-logo' to="/" style={{ textDecoration: 'none' }} onClick={() => setShowMenu(false)}>
            <h3 className='aces-h'>Web<span className='softa-span'>Softa</span><span className='com-span'>solutions</span></h3>
          </Link>
        </div>
        <button className='menu-btn' onClick={() => setShowMenu(!showMenu)}>{showMenu ? <HiOutlineX /> : <HiMenuAlt2 />}</button>
      </div>
      <Menu />
    </div>
  )
}

export default HeaderMob