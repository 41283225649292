import React, { useEffect, useState } from 'react';
import './HeaderEN.css';
import Contactbtn from '../Contactbtn/Contactbtn';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import HeaderMob from './HeaderMob';

const Header = () => {
  const [showContactButton, setShowContactButton] = useState(false);
  const location = useLocation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(currentScrollPos < 100 || currentScrollPos < prevScrollPos || screenWidth < 769); // Show header when scrolling up or near top
      setPrevScrollPos(currentScrollPos);
    };

    let prevScrollPos = window.scrollY;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
    {console.log(window.location.pathname)}
    // Show the contact button after a delay (adjust the delay as needed)
    const timer = setTimeout(() => {
      setShowContactButton(true);
    }, 900);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, [prevScrollPos]);

  return (
      <div className="header-content">
        <div className={`header ${visible ? 'visible' : 'hidden'}`}>
          {console.log(window.location.pathname)}
          <Link to="/" style={{ textDecoration: 'none' }}>
            <h2 className='aces-h'>Web<span className='softa-span'>Softa</span><span className='com-span'>solutions</span></h2>
          </Link>
        </div>
        <div className={`contact-button ${showContactButton ? 'show' : ''}`}>
        {location.pathname.toString()==='/' ? <Contactbtn /> : null }
      </div>
    </div>
  );
};

export default Header;