import React from 'react'
import { Link } from 'react-router-dom';

const Contactbtn = () => {
  return (
    <Link to="/contact">
      <button className='contact-btn'>Contact</button>
    </Link>
  )
}

export default Contactbtn