import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css'; // Import the CSS file
import AnimatedPage from '../../components/AnimatedPage/AnimatedPage';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace with your EmailJS service details
    const serviceID = 'service_4omgsj9';
    const templateID = 'template_7rz0x8i';
    const userID = 'I9-pawz5CfuRG5uCv';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('Email sent successfully!', response);
        // You can add a success message or clear the form here
        setFormData(() => ({
          name: '',
          email: '',
          message: ''
        }));
        setSent(true);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // You can add an error message here
      });
  };

  return (
      <div className='contact-container'>
      {/*  <div className='black-div'></div>*/}
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <h2>Contact</h2>
        <div className='contacts'>
          <div className='contact-form'>
            <p className='message-p'>Send me a message and I'll get back to you as soon as possible.</p>
            <form onSubmit={handleSubmit}>
              <div className='form-div'>
                <div>
                {/* <label htmlFor="send">Send a message</label> */}
                  <input
                    type="text"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    onFocus={() => setSent(false)}
                    required
                  />
                </div>
                <div>
                {/* <label htmlFor="email">Email:</label> */}
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    onFocus={() => setSent(false)}
                    required
                  />
                </div>
                <div>
                {/* <label htmlFor="message">Message:</label> */}
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    onFocus={() => setSent(false)}
                    required
                  />
                </div>
                <button className='btn-send' type="submit">Send Message</button>
              </div>
            </form>
            {(sent) ? <p className='p-sent'>Message sent</p> : <p className='empty-p'></p>}
          </div>
        </div>
      </div>
  );
};

export default Contact;
